<template>
    <div>
        <Header :customstyle="{ background: '#0C1913' }">
            <template slot="title">平移</template>
        </Header>
        <div class="content">
            <div class="card">
                <div class="ablesold">
                    <div class="num">{{ parseFloat(walletList[inputtopup]).toFixed(4) }}</div>
                    <div class="txt">可平移数量({{inputtopup}})</div>
                </div>
                <div class="exinput">
                    <div class="inputcontent" @click="clickInput">
                        <van-field
                                readonly
                                class="inputClass"
                                v-model="inputtopup"
                                placeholder=""

                        />
                    </div>
                    <!--@click="clickInput"-->
                    <img
                            class="arrow"
                            :src="require('@/assets/images/mine/darrow.png')"
                    />
                    <div class="inputcontent">
                        <van-field
                                class="inputClass"
                                disabled
                                placeholder="ATT"
                        />
                        <!--:placeholder="attNum+' ATT'"-->
                    </div>
                </div>
                <div class="inputcontent">
                    <van-field class="inputClass" v-model="inputtoNum" @keyup="checkNum"  placeholder="请输入平移数量" />
                </div>
                <!--<div class="poundage">手续费：-->
                    <!--{{  feeNum }} ATT-->
                <!--</div>-->
                <div class="paybtn">
                    <div class="btn" @click="toSubmitDui">平移</div>
                </div>
               <!-- <div class="poundage">平移比率：
                    <template v-if="inputtopup === 'EPT'">
                        1ATT = {{ config.ping_att_to_ept }} EPT
                    </template>
                    <template v-if="inputtopup === 'ARR'">
                        1ATT = {{ config.ping_att_to_arr }} ARR
                    </template>
                </div>-->
            </div>
            <div class="middlecontent">
                注意<br/>
                1. 原有平移，根据快照价值平移；<br/>
                2. 近期买入平移，根据实时比例
                50%优惠平移；<br/>
                3. 平移流程：转入→平移
                <!--1.最小兑换数量{{ config.dui_et_min }}ATT<br/>
                2.兑换比率:
                <template v-if="tmpInfo.coin_symbol === 'EPT'">
                    {{ config.ept_price / config.et_price}} : 1
                </template>
                <template v-if="tmpInfo.coin_symbol === 'ARR'">
                    {{ config.arr_price / config.et_price}} : 1
                </template>-->
            </div>
            <div class="record">
                <div class="title">平移记录</div>
                <div class="datalist">
                    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                        <van-list
                                v-model="loading"
                                :finished="finished"
                                finished-text="没有更多记录了"
                                @load="onLoad"
                        >
                            <div
                                    class="cell"
                                    v-for="(record, index) in recordList"
                                    :key="index"
                            >
                                <!-- <div class="date">{{ record.time }}</div> -->
                                <div class="detail">
                                    <div class="topnum">
                                        <div class="num">
                                            {{parseFloat(record.from_num).toFixed(3)}}
                                        </div>
                                        <div class="txt">平移数量({{record.from_symbol }})</div>
                                    </div>
                                    <div class="topnum" v-if="record.to_num>0">
                                        <div class="num">
                                            {{parseFloat(record.to_num).toFixed(3)}}
                                        </div>
                                        <div class="txt">平移获得({{record.to_symbol }})</div>
                                    </div>
                                </div>
                                <div class="detail">
                                    <div class="topnum">
                                        <div class="time">时间:{{record.ctime.substring(0,11)}}</div>
                                    </div>
                                    <div class="status">
                                        {{ getTransferTxt (record.status)}}
                                    </div>
                                </div>
                            </div>
                        </van-list>
                    </van-pull-refresh>
                </div>
            </div>
        </div>
        <van-picker class="picker" v-if="showPicker" show-toolbar title="选择平移" :columns="columns" :default-index="0"
                    @cancel="onCancel" @confirm="onConfirm"/>
        <van-dialog v-model="showpwd" title="兑换支付" @cancel="cancel"  @confirm="confirm" show-cancel-button>
            <van-field type="password" v-model="password" name="安全密码" label="安全密码" placeholder="请输入安全密码"></van-field>
        </van-dialog>
    </div>
</template>

<script>
    import {exchangeLog, getWalletInfo, duiConfig,fanDui,fanDuiLog} from '@/request/api'

    import Header from "../../components/header.vue";
    import { Toast } from 'vant';
    import $ from 'jquery'

    export default {
        components: {
            Header,
        },
        computed: {
            getTransferTxt() {
                return val => {
                    let txt = ''
                    switch (val) {
                        case -1:
                            txt = '失败(拒绝)'
                            break
                        case 0:
                            txt = '等待处理'
                            break
                        case 1:
                            txt = '成功'
                            break
                        case 2:
                            txt = '成功'
                            break
                    }
                    return txt
                }
            }
        },
        data() {
            return {
                showPicker: false,
                inputtopup: "EPT",
                refreshing: false,
                loading: false,
                finished: false,
                address: '',
                userInfo: {},
                recordList: [],
                columns: [
                    'ARR',
                    'EPT'
                ],
                currendPage: 1,
                walletInfo: [],
                tmpInfo: {},
                walletList:{
                    'ARR':0,
                    'ATT':0,
                    'EPT':0,
                    'USDT':0,
                },
                config: {
                    ept_price: "0.15",
                    arr_price: "2000",
                    et_price: "1",
                    dui_et_min: "100"
                },
                showpwd: false,
                password: '',
                inputtoNum:'',
                attNum:0,
                feeNum:0,
            };
        },
        activated() {
            this.address = sessionStorage.getItem("address");
            this.userInfo = sessionStorage.getItem("user")?JSON.parse(sessionStorage.getItem("user")):{};
            this.recordList = []
            this.getduiConfig()
            if(this.address){
                this.getWalletInfoData()
            }
        },
        methods: {
            checkNum(){
                if(parseFloat(this.inputtoNum)>0){
                    if(this.inputtopup=='ARR'){
                        this.attNum = parseFloat(this.inputtoNum)/parseFloat(this.config.ping_att_to_arr);
                    }else if(this.inputtopup=='EPT'){
                        this.attNum = parseFloat(this.inputtoNum)/parseFloat(this.config.ping_att_to_ept);
                    }
                }else{
                    this.attNum = 0;
                    this.feeNum = 0;
                }

            },
            toSubmitDui(){
                // Toast("暂未开放!");
                // return false;
                this.showpwd = true;
                this.password = '';
            },
            cancel() {
                this.showpwd = false;
                this.password = '';
            },
            confirm () {
                let _this = this;
                if(!this.address){
                    Toast('请使用币安智能链链接')
                    return
                }
                if(this.address&&this.userInfo.has_paypwd!=1){
                    Toast('请先完善资料,设置安全密码');
                    setTimeout(function () {
                        _this.$router.push({
                            path:'/home/mine'
                        })
                    })
                    return
                }
                if(this.password.length === 0){
                    Toast('请输入安全密码')
                    return;
                }
                if(this.inputtoNum<=0){
                    Toast('请输入兑换数量')
                    return;
                }
                if(parseFloat(this.inputtoNum)>parseFloat(this.walletList[this.inputtopup])){
                    Toast('可平移余额不足');
                    return;
                }
                const params = {
                    address:this.address,
                    pay_code:this.password,
                    amount:this.inputtoNum,
                    coin_symbol:this.inputtopup,
                }
                fanDui(params).then(res=> {
                    _this.password = '';
                    if(res.code==200){
                        Toast('平移成功')
                        setTimeout(function () {
                            location.reload();
                        },3000)
                    }else{
                        Toast(res.msg)
                    }
                }).catch(err=> {
                    Toast('平移失败')
                })
            },
            getduiConfig() {
                duiConfig().then(res => {
                    this.config = res.data
                })
            },
            getWalletInfoData() {
                let _this = this;
                const params = {
                    address: this.address,
                }
                getWalletInfo(params).then((res) => {
                    this.walletInfo = res.data.list
                   /* let tmpList = this.walletInfo.filter(item => {
                        return item.coin_symbol === 'ARR'
                    })*/
                    $.each(res.data.list,function (i,ele) {
                        _this.walletList[ele.coin_symbol] = parseFloat(ele.cloud_balance).toFixed(4);
                    })
                    console.log(_this.walletList)
                  /*  if (tmpList.length > 0) {
                        this.tmpInfo = tmpList[0]
                    }*/
                    // this.tmpInfo = this.walletInfo[0]
                })
            },

            onRefresh() {
                // 清空列表数据
                this.finished = false;
                this.currendPage = 1;
                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = true;
                this.onLoad();
            },
            onLoad() {
                if(this.address){
                    this.getDataList()
                    this.currentPage++;
                }else{
                    this.loading = false;
                }
            },
            getDataList() {
                const params = {
                    address: this.address,
                    page: this.currendPage,
                    limit: 20
                }
                fanDuiLog(params).then((res) => {
                    if (this.refreshing) {
                        this.recordList = [];
                        this.refreshing = false;
                    }
                    this.recordList = [...this.recordList, ...res.data.list]
                    this.loading = false;
                    if (res.data.list.length < 20) {
                        this.finished = true;
                    }
                })
            },
            onCancel() {
                this.showPicker = false
            },
            onConfirm(value) {
                this.showPicker = false
                this.inputtopup = value
                let tmpList = this.walletInfo.filter(item => {
                    return item.coin_symbol === value
                })
                if (tmpList.length > 0) {
                    this.tmpInfo = tmpList[0]
                }
                this.attNum = 0;
                this.inputtoNum = 0;
            },
            clickInput() {
                this.showPicker = true
            }
        },
    };
</script>

<style lang="scss" scoped>
    .picker {
        position: fixed;
        bottom: 0px;
        width: 100%;
    }

    .content {
        padding: 10px 32px;
        background: #0c1913;
        min-height: 100vh;
        .card {
            padding: 22px 24px;
            width: 686px;
            height: 658px;
            border-radius: 24px;
            opacity: 1;
            background: rgba(32, 40, 35, 1);
            .ablesold {
                width: 638px;
                height: 168px;
                border-radius: 16px;
                opacity: 1;
                border: 2px solid rgba(39, 204, 127, 0.2);
                background: rgba(16, 43, 29, 1);
                display: flex;
                flex-direction: column;
                justify-content: center;
                .num {
                    color: rgba(214, 255, 227, 1);
                    font-size: 44px;
                    font-weight: 500;
                    font-family: "DIN";
                    text-align: center;
                }
                .txt {
                    color: rgba(182, 217, 195, 1);
                    font-size: 24px;
                    font-weight: 400;
                    font-family: "PingFang SC";
                    text-align: center;
                    line-height: 36px;
                }
            }
            .inputcontent {
                margin-top: 16px;
                display: flex;
                justify-content: center;
                .inputClass {
                    width: 632px;
                    height: 96px;
                    background: #29332D;
                    border-radius: 16px;
                    ::v-deep .van-field__control{
                        color: white;
                    }
                }
            }
            .exinput {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 16px;
                .inputcontent {
                    width: 276px;
                    border-radius: 16px;
                    opacity: 1;
                    background: rgba(41, 51, 45, 1);
                    .inputClass {
                        width: 276px;
                        background: #29332d;
                        border-radius: 16px;
                        ::v-deep .van-field__control {
                            color: white;
                        }
                    }
                }
                .arrow {
                    width: 36px;
                    height: 36px;
                }
            }
            .paybtn {
                margin-top: 10px;
                display: flex;
                justify-content: center;
                .btn {
                    width: 320px;
                    height: 88px;
                    border-radius: 16px;
                    opacity: 1;
                    background: rgba(39, 204, 127, 1);
                    color: rgba(32, 40, 35, 1);
                    font-size: 36px;
                    font-weight: 700;
                    font-family: "PingFang SC";
                    text-align: center;
                    line-height: 88px;
                }
            }
            .poundage {
                margin-top: 16px;
                color: rgba(139, 166, 149, 1);
                font-size: 28px;
                font-weight: 400;
                font-family: "PingFang SC";
                text-align: center;
            }
        }
        .middlecontent {
            margin-top: 24px;
            margin-bottom: 48px;
            color: rgba(182, 217, 195, 1);
            font-size: 24px;
            font-weight: 700;
            text-align: left;
            font-family: "PingFang SC";
            line-height: 45px;
        }
        .record {
            border-radius: 24px;
            opacity: 1;
            background: rgba(32, 40, 35, 1);
            padding: 24px;
            .title {
                opacity: 1;
                color: rgba(214, 255, 227, 1);
                font-size: 32px;
                font-weight: 700;
                font-family: "PingFang SC";
            }
            .datalist {
                .date {
                    margin-top: 32px;
                    color: rgba(139, 166, 149, 1);
                    font-weight: 400;
                    text-align: left;
                    line-height: 52px;
                    font-size: 40px;
                }
                .detail {
                    display: flex;
                    justify-content: space-between;
                    .topnum {
                        margin-top: 32px;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                        .num {
                            color: rgba(214, 255, 227, 1);
                            font-size: 44px;
                            font-weight: 500;
                            font-family: "DIN";
                        }
                        .txt {
                            color: rgba(139, 166, 149, 1);
                            font-size: 24px;
                            font-weight: 400;
                            font-family: "PingFang SC";
                            text-align: center;
                            line-height: 36px;
                        }
                        .time {
                            color: rgba(139, 166, 149, 1);
                            font-size: 28px;
                            font-weight: 400;
                            font-family: "PingFang SC";
                        }
                    }
                    .status {
                        display: flex;
                        align-items: center;
                        color: rgba(39, 204, 127, 1);
                        font-size: 28px;
                        font-weight: 400;
                        font-family: "PingFang SC";
                    }
                }
            }
        }
    }
</style>